<template>
  <div class="c-TranslationSegments">
    <div class="c-TranslationSegments__controls">
      <div class="c-TranslationSegments__options">
        <span
          :class="{ active: layout === 'alignment' }"
          @click="toggleView('alignment')"
          >alignment</span
        >
        <span :class="{ active: layout === 'code' }" @click="toggleView('code')"
          >code</span
        >
      </div>

      <div class="c-TranslationSegments__actions">
        Show markups tags
        <SwitchToggle :data="inMarkupView" @change="inMarkupView = $event" />
      </div>
    </div>

    <div class="c-TranslationSegments__contentContainer">
      <div
        v-show="layout === 'code'"
        ref="TranslationSegmentsCodeContainer"
        class="c-TranslationSegments__codeContainer"
      />

      <div
        v-show="layout === 'alignment'"
        class="c-TranslationSegments__alignmentContainer"
      >
        <div class="c-TranslationSegments__tableContainer">
          <table
            class="c-TranslationSegments__table"
            :class="{ 'hide-markup': !inMarkupView }"
          >
            <thead>
              <tr>
                <th>No.</th>
                <th v-for="step in segments" :key="step.name">
                  <p>{{ step.name }}</p>
                  <p>{{ step.language }}</p>
                  <span v-if="step.inProgress" class="text--italic text--light">(In progress...)</span>
                </th>
              </tr>
            </thead>

            <tbody v-if="numberOfSegments > 0">
              <tr v-for="index in numberOfSegments"  :key="index">
                <td>{{ index }}</td>

                <td v-for="step in segments" :key="step.name">
                  <FastMTSegment v-if="isFastMT(step)" :segment="step.segments[index - 1]" :step="step" />
                  <Segment v-else :segment="step.segments[index - 1]" :step="step" />
                </td>
              </tr>
            </tbody>
          </table>

          <div v-if="numberOfSegments === 0" class="text--italic text--light text-center mt-10">
            No segments found for this translation
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import JSONFormatter from 'json-formatter-js'

import { SwitchToggle } from '@unbabel/ui'

import FastMTSegment from './FastMTSegment.vue'
import Segment from './Segment.vue'

export default {
  name: 'TranslationSegments',
  components: {
    FastMTSegment,
    Segment,
    SwitchToggle
  },
  props: {
    segments: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data () {
    return {
      layout: 'alignment',
      jsonFormatter: undefined,
      inMarkupView: true
    }
  },
  computed: {
    numberOfSegments () {
      return this.segments[0]?.number_of_segments ?? 0
    }
  },
  methods: {
    toggleView (type) {
      this.layout = type
    },
    isFastMT (step) {
      return step.type === 'fastmt'
    }
  },
  watch: {
    layout: {
      handler (newVal) {
        if (newVal) {
          Vue.nextTick().then(() => {
            // if there is segments info already displayed, clean it for the CODE tab
            if (this.$refs.TranslationSegmentsCodeContainer.firstChild) {
              this.$refs.TranslationSegmentsCodeContainer.removeChild(
                this.$refs.TranslationSegmentsCodeContainer.firstChild
              )
            }

            this.jsonFormatter = new JSONFormatter(this.segments, 2, {})
            this.$refs.TranslationSegmentsCodeContainer.appendChild(
              this.jsonFormatter.render()
            )
          })
        }
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .c-Label__text {
  max-width: 100%;
  text-transform: none;
}

.c-TranslationSegments {
  background-color: $un-white;
  height: 100%;
  display: flex;
  flex-direction: column;

  &.withScroll .c-TranslationSegments__fixed .c-TranslationSegments__table {
    z-index: 2;
    box-shadow: 3px 0 6px 0 rgba(174, 185, 203, 0.23),
      3px 0 6px 0 rgba(174, 185, 203, 0.23);
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }

  &__actions {
    font-size: 14px;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    gap: 1rem;
  }

  &__options {
    display: flex;

    span {
      font-family: $secondary-font;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: 0.15px;
      color: $un-n900;
      cursor: pointer;

      &:first-child {
        margin-right: calc(#{$base-margin} * 2);
      }

      &:hover {
        text-decoration: underline;
      }

      &.active {
        font-weight: 600;
        color: $un-blue;
        text-decoration: underline;
      }
    }
  }

  &__toggleContainer {
    display: flex;
    align-items: center;

    p {
      font-family: $primary-font;
      font-size: 14px;
      color: $un-n900;
      font-weight: 600;
      margin-right: calc(#{$base-margin} * 0.5);
      white-space: nowrap;
    }
  }

  &__contentContainer {
    height: calc(100% - 50px);
  }

  &__codeContainer {
    background-color: $un-n50;
    padding: calc(#{$base-padding} * 1.5);
    height: 100%;
    overflow: auto;
  }

  &__alignmentContainer {
    display: flex;
    position: relative;
    flex: 1;
    height: 100%;
  }
  &__tableContainer {
    min-height: 100%;
    overflow: auto;
    min-width: 100%;
  }

  &__table {
    background-color: $un-white;
    border-spacing: 0;
    width: 100%;

    &.hide-markup {
      ::v-deep .markup_tag {
        display: none !important;
      }
    }

    th,
    td {
      padding: $base-padding;
      text-align: left;
      vertical-align: middle;

      &:first-child {
        padding-left: calc(#{$base-padding} * 2);
        width: 30px;
      }

      &:last-child {
        padding-right: calc(#{$base-padding} * 2);
      }
    }

    thead th {
      color: $un-n900;
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      white-space: nowrap;

      p:first-child {
        margin-bottom: calc(#{$base-margin} / 2);
        font-weight: 600;
      }
    }

    tr {
      box-shadow: inset 0px -1px 0px 0px $un-n200;
    }

    td {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.21px;
      text-align: left;
      position: relative;
      vertical-align: top;

      &.inProgress,
      &.hasFailed {
        color: $un-n500;
        font-style: italic;
      }
    }
  }
}
</style>
