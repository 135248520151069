import Vue from 'vue'
import * as temporalService from '../services/temporalService'
import { GLOBAL_MUTATIONS } from '@/data/enum'
import { mergeExecutionTasks } from '../services/parsers'
import { isModuleHuman } from '../utils'

const getDefaultState = () => ({
  translations: [],
  nextPageToken: '',
  translationDetails: {},
  filters: {},
  selectedTranslation: null
})

function generateError (e, defaultError) {
  const { message } = e ?? {}

  if (message?.includes('Network Error')) {
    return new Error(`${defaultError}... <span class="text--bold mx-1">Ensure your VPN is connected</span> and try again.`)
  }

  console.error(e)
  return new Error(defaultError)
}

export default {
  namespaced: true,
  state: () => getDefaultState(),
  getters: {
    translationById (state) {
      return (id) => state.translations.find(t => t.id === id)
    },
    currentTranslationIndex (state) {
      return state.translations.findIndex(t => t.executionId === state.selectedTranslation)
    },
    translation (state) {
      return state.translationDetails[state.selectedTranslation] ?? null
    }
  },
  mutations: {
    resetState (state) {
      state = getDefaultState()
    },
    setTranslationDetails (state, { translationId, value }) {
      Vue.set(state.translationDetails, translationId, value)
    },
    setStateValue (state, { field, value }) {
      Vue.set(state, field, value)
    }
  },
  actions: {
    selectTranslation ({ state, getters, dispatch }, offset = 1) {
      const index = getters.currentTranslationIndex
      if (index < 0 || index > state.translations.length - 1) return

      const translationId = state.translations[index + offset].executionId
      dispatch('fetchTranslationDetails', { translationId })
      return translationId
    },
    async fetchTranslations ({ commit }, filters = {}) {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true, { root: true })

      try {
        const { results, nextPageToken } = await temporalService.getAllTranslations({ query: filters })

        commit('setStateValue', { field: 'filters', value: filters })
        commit('setStateValue', { field: 'translations', value: results ?? [] })
        commit('setStateValue', { field: 'nextPageToken', value: nextPageToken })
      } catch (e) {
        throw generateError(e, 'Unable to get your translations')
      } finally {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false, { root: true })
      }
    },
    async fetchNextPage ({ state, commit }) {
      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true, { root: true })

      try {
        const { results, nextPageToken } = await temporalService.getAllTranslations({ query: state.filters, nextPageToken: state.nextPageToken })

        commit('setStateValue', { field: 'translations', value: [...state.translations, ...results] })
        commit('setStateValue', { field: 'nextPageToken', value: nextPageToken })
      } catch (e) {
        throw generateError(e, 'Error getting more translations')
      } finally {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false, { root: true })
      }
    },
    async fetchTranslationDetails ({ state, dispatch, commit }, { translationId, force }) {
      if (!force && state.translationDetails[translationId]) {
        commit('setTranslationDetails', { translationId, value: state.translationDetails[translationId] })
        return state.translationDetails[translationId]
      }

      commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true, { root: true })

      try {
        let data = await temporalService.getTranslation(translationId)

        const htSteps = data.stepExecution.filter(step => isModuleHuman(step.module))

        if (htSteps.length > 0) {
          const { uid } = data.request ?? {}
          const taskIds = new Set()

          htSteps.forEach((step) => {
            const results = step.results?.[0]?.transient_data?.task_results ?? []

            results.forEach(task => {
              if (!task.id.startsWith('skipped')) {
                taskIds.add(task.id)
              }
            })
          })

          const tasks = await dispatch('tasks/getTranslationTasks', { taskIds: Array.from(taskIds), translationId: uid, force }, { root: true })
          data = mergeExecutionTasks(data, tasks)
        }

        commit('setTranslationDetails', { translationId, value: { ...data, dev: { fetchedAt: Date.now() } } })
        return data
      } catch (e) {
        throw generateError(e, 'Error getting translation with uid ' + translationId)
      } finally {
        commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false, { root: true })
      }
    }
  }
}
