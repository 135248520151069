<template>
  <div class="c-TranslationFilters">
    <div class="c-TranslationFilters__search">
      <div class="c-TranslationFilters__searchContent">
        <GroupedInputText
          class="c-TranslationFilters__input"
          select-label="Search"
          :select-items="ID_SELECT_OPTIONS"
          input-label-prefix="Search by"
          :selectWidth="181"
          :data="parseGroupedInputTextValue"
          @change="updateFilters"
        >
        </GroupedInputText>
        <ChipMultiSelect
          class="c-TranslationFilters__input"
          :data="searchFilters[FILTERS_DICT.STATUS]"
          :select-items="STATUS_OPTIONS"
          label="Status"
          @change="updateFilters(FILTERS_DICT.STATUS, $event)"
        />
        <ChipMultiSelect
          class="c-TranslationFilters__input"
          label="Template"
          :data="searchFilters[FILTERS_DICT.TEMPLATE]"
          :select-items="templateFormOptions"
          @change="updateFilters(FILTERS_DICT.TEMPLATE, $event)"
        >
        </ChipMultiSelect>
        <ChipMultiSelect
          class="c-TranslationFilters__input"
          label="Source Language"
          :data="searchFilters[FILTERS_DICT.SOURCE_LANGUAGE]"
          :select-items="langOptions"
          @change="updateFilters(FILTERS_DICT.SOURCE_LANGUAGE, $event)"
        >
        </ChipMultiSelect>
        <ChipMultiSelect
          class="c-TranslationFilters__input"
          label="Target Language"
          :data="searchFilters[FILTERS_DICT.TARGET_LANGUAGE]"
          :select-items="langOptions"
          @change="updateFilters(FILTERS_DICT.TARGET_LANGUAGE, $event)"
        />
        <ChipMultiSelect
          class="c-TranslationFilters__input client"
          label="Client"
          :select-items="canonicalNameOptions"
          :from-external-source="true"
          :is-data-loading="isCanonicalNameDataLoading"
          :min-char-match="2"
          :data="searchFilters[FILTERS_DICT.CANONICAL_NAME]"
          @search="handleCanonicalNameSearch"
          @change="updateFilters(FILTERS_DICT.CANONICAL_NAME, $event)"
        />
        <OldDateTimePicker
          class="c-TranslationFilters__input"
          :data="searchFilters[FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN]"
          :max-date="maxDate"
          label="From Created Date"
          :has-clear-button="false"
          @change="
            updateFilters(FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN, $event)
          "
        >
        </OldDateTimePicker>
        <OldDateTimePicker
          class="c-TranslationFilters__input"
          :data="searchFilters[FILTERS_DICT.DATE_LESS_THAN]"
          :min-date="minDate"
          label="To Created Date"
          :has-clear-button="false"
          @change="updateFilters(FILTERS_DICT.DATE_LESS_THAN, $event)"
        >
        </OldDateTimePicker>
      </div>

      <div class="c-TranslationFilters__buttonsRow">
        <Button
          class="clear"
          label="clear all"
          button-type="is-text"
          data-test-id="clear-search"
          @click="clearSearch"
        />
        <Button
          label="search"
          button-type="is-contained"
          data-test-id="search-btn"
          @click="search"
        />
      </div>
    </div>

    <div class="c-TranslationFilters__results">
      <button type="button" @click="search">
        <span>
          Updated <b>{{ refreshTimeStr }} </b>ago
        </span>
        <i class="update"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ChipMultiSelect, GroupedInputText, Button } from '@unbabel/ui'
import { formatDistance } from 'date-fns'

import { FILTERS_DICT, GLOBAL_MUTATIONS } from '@/data/enum'
import { updateURLparams, parseURLparams } from '@/utils/urlUtils'
import OldDateTimePicker from '@/components/OldDateTimePicker'
import { showNotification } from '@/utils/generalUtils'
import {
  ID_SELECT_OPTIONS,
  STATUS_OPTIONS,
  languageFormOptions,
  templateFormOptions
} from '../constants/options'

const SEARCH_IDS = ID_SELECT_OPTIONS.map(({ value }) => value)

export default {
  name: 'TranslationFilters',
  components: {
    ChipMultiSelect,
    GroupedInputText,
    OldDateTimePicker,
    Button
  },
  data () {
    return {
      searchFilters: {},
      minDate: undefined,
      maxDate: undefined,
      canonicalNameOptions: [],
      isCanonicalNameDataLoading: false,
      latestRefresh: new Date().getTime(),
      refreshTimeStr: undefined,
      refreshInterval: undefined,
      templateFormOptions: templateFormOptions(),
      langOptions: languageFormOptions(),
      FILTERS_DICT,
      STATUS_OPTIONS,
      ID_SELECT_OPTIONS
    }
  },
  computed: {
    parseGroupedInputTextValue () {
      const setFilter = this.getIdFilterSet()
      return { [setFilter]: this.searchFilters[setFilter] }
    }
  },
  methods: {
    ...mapActions('customers', ['fetchCustomers']),
    getIdFilterSet () {
      const firstItem = SEARCH_IDS[0]
      return SEARCH_IDS.find(type => this.searchFilters[type]?.length) ?? firstItem
    },
    handleCanonicalNameSearch (search) {
      this.isCanonicalNameDataLoading = true
      this.fetchCustomers(search)
        .then(res => {
          this.isCanonicalNameDataLoading = false
          this.canonicalNameOptions = res.map(item => {
            return { label: item.friendly_name, value: item.id }
          })
        })
        .catch(e => {
          showNotification(this, 'is-alert', e)
        })
    },
    parseCustomerFilterValue (value) {
      return value?.split(',,').map(item => item.split('_***_').pop())
    },
    generateObject (key, transform = value => value, symbol = '=', val) {
      const value = val ?? this.searchFilters[key]

      if (!value) return
      return { value: transform(value), symbol }
    },
    async getParsedFilters () {
      const commaTransformer = val => val.split(',')
      const dateTransformer = val => new Date(val).toISOString()

      const out = {}

      out.ExecutionStatus = this.generateObject(
        FILTERS_DICT.STATUS,
        commaTransformer,
        'IN'
      )
      out.SourceLanguage = this.generateObject(
        FILTERS_DICT.SOURCE_LANGUAGE,
        commaTransformer,
        'IN'
      )
      out.TargetLanguage = this.generateObject(
        FILTERS_DICT.TARGET_LANGUAGE,
        commaTransformer,
        'IN'
      )
      out.TemplateType = this.generateObject(
        FILTERS_DICT.TEMPLATE,
        commaTransformer,
        'IN'
      )
      out.StartTime = this.generateObject(
        FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN,
        dateTransformer,
        '>='
      )
      out.CloseTime = this.generateObject(
        FILTERS_DICT.DATE_LESS_THAN,
        dateTransformer,
        '<='
      )
      out.CustomerID = this.generateObject(
        FILTERS_DICT.CANONICAL_NAME,
        this.parseCustomerFilterValue,
        'IN'
      )
      out.ProjectID = this.generateObject(FILTERS_DICT.PROJECT_ID)
      out.WorkflowId = this.generateObject(FILTERS_DICT.UID)
      out.ExternalMessageID = this.generateObject(FILTERS_DICT.EXTERNAL_ID)
      out.ThreadID = this.generateObject(FILTERS_DICT.THREAD_ID)
      out.ExternalThreadID = this.generateObject(
        FILTERS_DICT.THREAD_EXTERNAL_ID
      )

      const idFilter = this.getIdFilterSet()
      // Fetch translation IDs from tarkin tasks/records
      const tarkinFilters = [FILTERS_DICT.TASK_ID, FILTERS_DICT.TRANSLATION_RECORD_ID]
      if (tarkinFilters.includes(idFilter)) {
        const messageIds = new Set()
        let tasks = []

        if (this.searchFilters[FILTERS_DICT.TASK_ID]) {
          tasks = await this.$store
            .dispatch('tasks/getTranslationTasks', {
              taskIds: [this.searchFilters[FILTERS_DICT.TASK_ID]]
            })
            .catch(e => showNotification(this, 'is-alert', e))
        } else {
          const record = await this.$store
            .dispatch('tasks/getRecord', {
              recordId: [this.searchFilters[FILTERS_DICT.TRANSLATION_RECORD_ID]]
            })
            .catch(e => showNotification(this, 'is-alert', e))

          if (record?.task) tasks = [record.task]
        }

        if (!tasks.length) throw new Error('No tasks found for this filter')

        tasks.forEach(task => messageIds.add(task.group_id))
        out.WorkflowId = this.generateObject(
          FILTERS_DICT.UID,
          undefined,
          'IN',
          Array.from(messageIds)
        )
      }

      const entries = Object.entries(out).filter(
        ([_, value]) => value !== undefined
      )
      return Object.fromEntries(entries)
    },
    updateFilters (key, value) {
      if (key === FILTERS_DICT.DATE_LESS_THAN) {
        this.maxDate = value
      } else if (key === FILTERS_DICT.DATE_GREATER_OR_EQUAL_THAN) {
        this.minDate = value
      }

      this.searchFilters[key] = value

      if (SEARCH_IDS.includes(key)) {
        SEARCH_IDS.forEach(idKey => {
          if (key === idKey) return
          this.searchFilters[idKey] = null
        })
      }
    },
    async search () {
      this.latestRefresh = new Date()

      await updateURLparams(this, this.searchFilters, 'Flexible')

      try {
        this.$store.commit(GLOBAL_MUTATIONS.UPDATE_LOADING, true)
        const filters = await this.getParsedFilters()
        this.$store
          .dispatch('watchtower/fetchTranslations', filters)
          .catch(e => showNotification(this, 'is-alert', e))
      } catch {
        this.$store.commit('watchtower/setStateValue', {
          field: 'translations',
          value: []
        })
      } finally {
        this.$store.commit(GLOBAL_MUTATIONS.UPDATE_LOADING, false)
      }
    },
    clearSearch () {
      this.searchFilters = {}
      this.$router.replace({ query: {} })
      this.search()
    }
  },
  beforeMount () {
    const setTimer = () => {
      this.refreshTimeStr = formatDistance(
        this.latestRefresh,
        new Date().getTime()
      )
    }

    Object.entries(parseURLparams(this)).forEach(([key, val]) => {
      this.searchFilters[key] = val
    })

    this.search()
    setTimer()

    this.refreshInterval = setInterval(setTimer, 1000)
  },
  beforeDestroy () {
    this.refreshInterval && clearInterval(this.refreshInterval)
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TranslationFilters {
  h4 {
    font-family: $secondary-font;
    font-size: 21px;
    font-weight: 600;
    line-height: 1.52;
    color: $un-n900;
    margin-bottom: $base-margin;
  }

  &__search {
    border-radius: $border-radius;
    background-color: $un-white;
    padding: calc(#{$base-padding} * 1.5);
    margin-bottom: calc(#{$base-margin} * 2.5);
    border: 1px solid $un-n200;
  }

  &__searchContent {
    display: flex;
    flex-wrap: wrap;
  }

  &__input {
    width: calc(33.3% - calc(#{$base-margin} * (2 / 3)));
    margin-right: $base-margin;
    margin-bottom: $base-margin;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }

  &__buttonsRow {
    display: flex;
    justify-content: flex-end;

    .clear {
      margin-right: $base-margin;
    }
  }

  &__results {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: $base-margin;

    button {
      border: none;
      background-color: transparent;
      margin: 0 10px;
      padding: 0;
      font-family: $primary-font;
      font-size: 14px;
      color: $un-n900;
      cursor: pointer;
      display: flex;

      span {
        margin-right: 5px;
      }

      b {
        font-weight: 600;
      }

      i {
        margin-top: 1px;
        width: 18px;
        height: 18px;
        display: inline-block;
        background-size: cover;
        background-repeat: no-repeat;

        &.update {
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24'%3E%3Cpath fill='%23707A89' fill-rule='evenodd' d='M21.375 5.495a.625.625 0 010 1.25h-2.196a8.756 8.756 0 011.569 5.18 8.703 8.703 0 01-2.69 6.134 8.717 8.717 0 01-6.062 2.438 8.706 8.706 0 01-3.365-.674.62.62 0 01-.337-.817.624.624 0 01.817-.338 7.451 7.451 0 002.554.571l.33.008a7.468 7.468 0 005.196-2.09 7.452 7.452 0 002.306-5.259A7.482 7.482 0 0018.249 7.6v2.27a.625.625 0 01-1.249 0V6.12c0-.345.28-.625.625-.625h3.75zM12.012 3c1.156 0 2.286.226 3.356.67a.622.622 0 01.337.817.623.623 0 01-.817.336 7.478 7.478 0 00-2.876-.574c-1.944 0-3.787.74-5.19 2.084a7.447 7.447 0 00-2.313 5.255 7.487 7.487 0 001.241 4.299v-2.268A.625.625 0 117 13.62v3.75c0 .345-.28.625-.625.625h-.198l-.038.003-.041-.003H2.625a.625.625 0 010-1.25h2.198a8.739 8.739 0 01-1.563-5.183A8.693 8.693 0 015.958 5.43 8.712 8.712 0 0112.012 3z'/%3E%3C/svg%3E");
        }

        &.download {
          background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxOS4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0ODUgNDg1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0ODUgNDg1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PHBhdGggZD0iTTQyNi41LDQ1OGgtMzY4QzUxLDQ1OCw0NSw0NjQsNDUsNDcxLjVTNTEsNDg1LDU4LjUsNDg1aDM2OGM3LjUsMCwxMy41LTYsMTMuNS0xMy41UzQzNCw0NTgsNDI2LjUsNDU4eiIvPjxwYXRoIGQ9Ik0yMzMsMzc4LjdjMi41LDIuNSw2LDQsOS41LDRzNy0xLjQsOS41LTRsMTA3LjUtMTA3LjVjNS4zLTUuMyw1LjMtMTMuOCwwLTE5LjFjLTUuMy01LjMtMTMuOC01LjMtMTkuMSwwTDI1NiwzMzYuNXYtMzIzQzI1Niw2LDI1MCwwLDI0Mi41LDBTMjI5LDYsMjI5LDEzLjV2MzIzbC04NC40LTg0LjRjLTUuMy01LjMtMTMuOC01LjMtMTkuMSwwcy01LjMsMTMuOCwwLDE5LjFMMjMzLDM3OC43eiIvPjwvZz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PC9zdmc+");
        }
      }
    }
  }
}
</style>
