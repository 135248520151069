<template>
  <div class="c-TranslationFlowText">
    <Card title="Translation Text">
      <div slot="header-actions" class="c-TranslationFlowText__cardActions">
        <Button
          button-type="is-outlined"
          :disabled="!hasSegments"
          label="Segments"
          @click="toggleSegmentsInfo"
        />
      </div>

      <div slot="body" class="c-TranslationFlowText__cardBody">
        <div class="c-TranslationFlowText__translation">
          <div class="c-TranslationFlowText__languageHeader">
            <div class="c-TranslationFlowText__language">
              <img :src="`/flags-light/${requestData.sourceLanguage}.svg`" />
              <p>
                <span>{{ requestData.sourceLanguageLabel }}</span>
                <span v-if="requestData.author">
                  by {{ requestData.author }}</span
                >
              </p>
            </div>
            <span class="c-TranslationFlowText__numWords">
              {{ requestData.numberOfWords }} words
            </span>
          </div>
          <p v-if="requestData.text" class="c-TranslationFlowText__text">
            {{ requestData.text }}
          </p>

          <button @click="downloadText('source')">
            <Icon
              class="download-icon"
              icon="download-thick-bottom"
              icon-style="is-dark-gray"
            />
          </button>
        </div>

        <div class="c-TranslationFlowText__translation">
          <div class="c-TranslationFlowText__languageHeader">
            <div class="c-TranslationFlowText__language">
              <img :src="`/flags-light/${requestData.targetLanguage}.svg`" />
              <p>
                <span>{{ requestData.targetLanguageLabel }}</span>
                <span v-if="requestData.recipient"
                  >to {{ requestData.recipient }}</span
                >
              </p>
            </div>
            <span
              v-if="resultData.numberOfWords"
              class="c-TranslationFlowText__numWords"
            >
              {{ resultData.numberOfWords }} words
            </span>
          </div>

          <p v-if="resultData.text" class="c-TranslationFlowText__text">
            {{ resultData.text }}
          </p>
          <p v-else class="text--body-2 text--light text-center">
            Step not completed
          </p>
          <button v-if="resultData.text" @click="downloadText('target')">
            <Icon
              class="download-icon"
              icon="download-thick-bottom"
              icon-style="is-dark-gray"
            />
          </button>
        </div>
      </div>
    </Card>

    <Modal
      v-if="hasSegments"
      :is-visible="nuggetsVisibility"
      title="Segments"
      @toggleVisibility="toggleSegmentsInfo"
    >
      <TranslationSegments :segments="segments" />

      <template #legend>
        <NuggetsLegend />
      </template>
    </Modal>
  </div>
</template>

<script>
import { Card, Button, Icon } from '@unbabel/ui'
import { mapActions } from 'vuex'

import Modal from '@/components/Modal'
import NuggetsLegend from '@/components/NuggetsLegend'
import TranslationSegments from '../TranslationSegments/TranslationSegments'

import { languageOptions } from '@/data/languages'
import { getSegments, getFastMTSegments, getResultData } from '../../utils'
import { getTagValue, downloadFile } from '@/utils/generalUtils'

export default {
  name: 'TranslationFlowText',
  components: {
    Card,
    Button,
    Icon,
    Modal,
    NuggetsLegend,
    TranslationSegments
  },
  props: {
    translation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      customer: null,
      nuggetsVisibility: false
    }
  },
  computed: {
    requestData () {
      const { request } = this.translation
      const data = getResultData(this.translation.stepExecution?.[0]?.results ?? [])
      const numberOfWords = data?.translated_data?.source_num_words

      return {
        sourceLanguage: request.source_language,
        targetLanguage: request.target_language,
        targetLanguageLabel:
          languageOptions[request.target_language]?.label ?? 'Unknown',
        sourceLanguageLabel:
          languageOptions[request.source_language]?.label ?? 'Unknown',
        numberOfWords,
        text: request.text,
        author: getTagValue(request, 'author_id'),
        recipient: null
      }
    },

    resultData () {
      const { results } = this.translation
      if (!results) return {}

      const data = getResultData(results)

      return {
        numberOfWords: data?.translated_data?.target_num_words,
        text: data?.translated_content ?? ''
      }
    },
    segments () {
      const { stepExecution, workflow } = this.translation

      const config = {
        glossaryId: getTagValue(workflow, 'glossary_id'),
        customerId: this.customer?.canonical_name
      }

      const fastMT = stepExecution.find(step => step.module === 'fastmt-run')
      if (fastMT) return getFastMTSegments(fastMT, config)
      return getSegments(stepExecution, config)
    },

    hasSegments () {
      return this.segments.length > 0
    }
  },
  methods: {
    ...mapActions('customers', ['getCustomer']),
    toggleSegmentsInfo () {
      this.nuggetsVisibility = !this.nuggetsVisibility
    },
    downloadText (type) {
      let text
      if (type === 'source') {
        text = this.requestData.text
      }

      if (type === 'target') {
        text = this.resultData.text
      }

      downloadFile(`${type}.txt`, text)
    }
  },
  watch: {
    translation: {
      async handler (newVal) {
        const customer = await this.getCustomer({ id: newVal.request.customer_id })
        this.customer = customer ?? null
      },
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TranslationFlowText {
  &__cardActions {
    display: flex;

    button {
      margin-right: $base-margin;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__cardBody {
    display: flex;
  }

  &__translation {
    width: 50%;
    position: relative;

    &:first-child {
      padding-right: calc(#{$base-padding} * 1.5);
      position: relative;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: -16px;
        right: 0;
        width: 1px;
        height: calc(100% + 16px + 16px);
        background-color: $un-n200;
      }
    }

    &:last-child {
      padding-left: calc(#{$base-padding} * 1.5);
    }

    button {
      border: none;
      margin: $base-margin 0 0 0;
      padding: 0;
      background-color: transparent;
      float: right;
      cursor: pointer;
    }
  }

  &__languageHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: calc(#{$base-margin} * 1.5);
  }

  &__language {
    display: flex;

    img {
      height: 40px;
      object-fit: contain;
      margin-right: $base-margin;
    }

    p span {
      &:first-child {
        font-family: $secondary-font;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.1px;
        color: $un-n900;
      }
      &:last-child {
        font-family: $primary-font;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: $un-n700;
        display: block;
      }
    }
  }

  &__numWords {
    font-family: $primary-font;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.4px;
    color: $un-n700;
  }

  &__text {
    font-family: $primary-font;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.25px;
    color: $un-n900;
    overflow-wrap: break-word;
  }
}
</style>
