<template>
  <div>
    <table class="c-TranslationsTable">
      <thead>
        <tr>
          <th class="c-TranslationsTable__translationTypeHeader">
            Translation
          </th>
          <th>Client</th>
          <th>Pipeline</th>
          <th>Project</th>
          <th class="c-TranslationsTable__statusHeader">Status</th>
          <th class="c-TranslationsTable__datesHeader">Dates</th>
          <th class="c-TranslationsTable__timeSpentHeader">Time spent</th>
        </tr>
      </thead>

      <tbody>
        <!-- Empty state -->
        <tr v-show="!isLoading && !data.length">
          <td colspan="9" class="c-TranslationsTable__empty">
            No results were found for the specified search parameters. You
            should try clearing or changing the search parameters.
          </td>
        </tr>

        <!-- Loading state -->
        <template v-if="isLoading && !data.length">
          <TranslationsLoadingRow v-for="i in 5" :key="i" />
        </template>

        <!-- Default state -->
        <tr
          v-for="(item, index) in data"
          v-else
          v-show="data.length"
          :key="index"
          :data-index="index"
          class="c-TranslationsTable__tableRow"
          @click="onRowClicked(item)"
        >
          <td>
            <CopyID :itemId="item.executionId" label="Message UID" entity="translation" />
            <CopyID v-if="item.threadId" :itemId="item.threadId" label="Thread ID" entity="thread" class="mt-4" />
          </td>

          <td>
            <AsyncEntityNameCell
              :id="item.customerId"
              :fetch="id => getCustomer({ id })"
              entityKey="friendly_name"
              class="text--bold mb-2"
            />

            <CopyID :itemId="item.customerId" entity="customer" />
          </td>

          <td>
            <p class="text--bold">Template</p>
            <p class="text--light mt-2">{{ TEMPLATE_LABEL[item.templateType] || '---' }}</p>
            <LanguagePair
              class="mt-5"
              :source="item.sourceLanguage"
              :target="item.targetLanguage"
            />
          </td>

          <td>
            <AsyncEntityNameCell
              v-if="item.projectId"
              :id="item.projectId"
              :fetch="id => getProject({ id, customerId: item.customerId })"
              emptyText=""
              class="text--bold mb-2"
            />

            <CopyID :itemId="item.projectId" entity="project" />
          </td>

          <td class="c-TranslationsTable__status">
            <StatusChip v-bind="generateStatus(item)" />
          </td>

          <td class="c-TranslationsTable__dates">
            <p class="text--caption">Creation Date</p>
            <p class="text--caption--demi">{{ item.startedAt || "---" }}</p>
            <div v-if="item.endedAt" class="mt-1">
              <p class="text--caption">Completion Date</p>
              <p class="text--caption--demi">{{ item.endedAt }}</p>
            </div>
            <div v-if="item.deadline" class="mt-1">
              <p class="text--caption">Deadline Date</p>
              <p class="text--caption--demi">{{ item.deadline }}</p>
            </div>
          </td>

          <td class="c-TranslationsTable__timeSpent">
            <span>{{ generateDuration(item) }}</span>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-show="!isLoading && data.length"
      class="c-TranslationsTable__pagination"
    >
      <p>Showing {{ data.length }} translations</p>
      <Button
        v-if="hasNextPage"
        label="load more"
        button-type="is-outlined"
        @click="nextPage"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import { formatDistanceStrict } from 'date-fns'
import { Button } from '@unbabel/ui'

import StatusChip from '@/components/StatusChip'
import LanguagePair from '@/components/LanguagePair'
import TranslationsLoadingRow from './TranslationsLoadingRow.vue'
import AsyncEntityNameCell from './AsyncEntityNameCell.vue'
import CopyID from './CopyID.vue'

import { generateCPLinks, showNotification } from '@/utils/generalUtils'
import { parseTranslationStatusToStyle } from '../services/parsers'
import { GLOBAL_GETTERS, TEMPLATE_LABEL } from '@/data/enum'

export default {
  name: 'Table',
  components: {
    StatusChip,
    TranslationsLoadingRow,
    AsyncEntityNameCell,
    CopyID,
    LanguagePair,
    Button
  },
  data () {
    return { TEMPLATE_LABEL }
  },
  props: {
    data: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  computed: {
    ...mapState('watchtower', ['nextPageToken']),
    ...mapGetters({ isLoading: GLOBAL_GETTERS.LOADING }),
    hasNextPage () {
      return !!this.nextPageToken
    }
  },
  methods: {
    ...mapMutations('watchtower', ['setStateValue']),
    ...mapActions({
      fetchNextPage: 'watchtower/fetchNextPage',
      getCustomer: 'customers/getCustomer',
      getProject: 'projects/getProject'
    }),
    onRowClicked (item) {
      this.$router.push({ name: 'Flexible', query: this.$route.query, params: { id: item.executionId } })
    },
    nextPage () {
      this.fetchNextPage().catch(e => showNotification(this, 'is-alert', e))
    },
    redirect (translation, type) {
      window.open(
        `${generateCPLinks(translation, type)}`,
        '_blank',
        'noopener,noreferrer'
      )
    },
    fetchProject (id, item) {
      return this.getProject({ projectId: id, customerId: item.customerId })
    },
    generateStatus (item) {
      return {
        status: item.status,
        statusConfig: parseTranslationStatusToStyle(item.status)
      }
    },
    generateDuration (item = {}) {
      if (item.duration === undefined) return '---'

      return formatDistanceStrict(0, item.duration * 1000, {
        roundingMethod: 'ceil'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TranslationsTable {
  min-width: 100%;
  border: 1px solid $un-n200;
  border-radius: $border-radius;
  position: relative;
  overflow-x: auto;
  background-color: $un-white;
  border-spacing: 0;
  table-layout: fixed;

  thead th {
    color: $un-n900;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    vertical-align: middle;
  }

  th,
  td {
    padding: $base-padding;
    text-align: left;
    vertical-align: top;
  }

  tbody tr {
    cursor: pointer;

    &:hover {
      background-color: $un-n50;
    }

    &:last-child {
      box-shadow: none;
    }
  }

  tr {
    vertical-align: middle;
    box-shadow: inset 0px -1px 0px 0px $un-n200;

    td {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.21px;
      text-align: left;
    }
  }

  &__empty {
    font-size: 14px !important;
    padding-top: calc(#{$base-padding} * 4) !important;
    padding-bottom: calc(#{$base-padding} * 4) !important;
    text-align: center !important;
    pointer-events: none;
  }

  &__id {
    position: relative;
  }

  &__status,
  &__statusHeader {
    white-space: nowrap;

    > p {
      margin-bottom: calc(#{$base-margin} / 2);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__pagination {
    text-align: center;
    padding: calc(#{$base-padding} * 1.5) 0 calc(#{$base-padding} * 2) 0;

    p {
      font-family: $primary-font;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      margin-bottom: $base-margin;
    }

    button {
      margin: 0 auto;
    }
  }
}
</style>
