<template>
  <div>
    <Card
      class="c-TaskRecords"
      :title="`Translation Records (${records.length})`"
    >
      <Button
        v-if="isSeniorReview"
        slot="header-actions"
        label="editor url"
        button-type="is-outlined"
        icon="copy-duplicate"
        @click="copyUrlToClipboard"
      />

      <div slot="body">
        <p v-if="records.length === 0" class="c-TaskRecords__emptyState">
          <span>No Translation Records have been created yet</span>
        </p>

        <table v-else class="c-TaskRecords__table">
          <thead>
            <tr>
              <th />
              <th>Editor</th>
              <th>Edit Information</th>
              <th>Time taken</th>
              <th>Device</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr v-for="(item, index) in paginatedPages" :key="index">
              <td>
                <StatusChip :status="item.action" class="mb-2" />
                <CopyID
                  small
                  :extendable="false"
                  :itemId="item.id"
                  entity="record"
                />
              </td>
              <td class="c-TaskRecords__editor">
                <span v-if="!item.editor || !item.editor.name"> --- </span>
                <a
                  v-else
                  :id="`treditor${index}`"
                  :href="uadminEditorUrl(item.editor.name)"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {{ item.editor.name }}
                  <Tooltip
                    message="Go to editor profile"
                    position="top"
                    :anchorName="`treditor${index}`"
                  />
                </a>

                <p class="text--caption text--light">
                  Rate: {{ getHourlyRate(item) }}
                </p>
              </td>
              <td>
                <div>
                  <span class="text--caption">Cost: </span>
                  <span class="text--caption--demi ml-1">
                    {{ getEditingInfo(item).cost }}
                  </span>
                </div>

                <div>
                  <span class="text--caption mt-2">Time: </span>
                  <span class="text--caption--demi ml-1">
                    {{ getEditingInfo(item).time }}
                  </span>
                </div>

                <div>
                  <span class="text--caption mt-2">Distance: </span>
                  <span class="text--caption--demi ml-1">
                    {{ getEditingInfo(item).distance }}
                  </span>
                </div>
              </td>
              <td class="c-TaskRecords__timeTaken">
                {{ item.parsed_duration || item.duration || "---" }}
              </td>
              <td>
                {{ item.device || "---" }}
              </td>

              <td>
                <p class="text--caption">Creation Date</p>
                <p class="text--caption--demi">
                  {{ item.parsed_created_at || "---" }}
                </p>

                <div v-if="item.parsed_completed_at" class="mt-2">
                  <p class="text--caption">Completed at</p>
                  <p class="text--caption--demi">
                    {{ item.parsed_completed_at || "---" }}
                  </p>
                </div>
              </td>

              <td @click.stop="">
                <ActionMenu
                  :menu-items="[{ label: 'Reassign task', value: 'reassign' }]"
                  position="top"
                  @menuClick="focusedRecordId = item.id"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>

    <ConfirmationModal
      :visibility="focusedRecordId !== null"
      title="Reassign Task"
      body="Are you sure you want to reassign this task?"
      :disabled="loading"
      @cancel="clearModal"
      @confirm="reAssign"
    />

    <Pagination
      v-if="totalPages > 1"
      class="d-flex center-xs"
      :currentPage="currPage"
      :paginationItems="paginationItems"
      :totalPages="totalPages"
      @changePage="handleChangePage"
    />
  </div>
</template>

<script>
import { Tooltip, Card, Button } from '@unbabel/ui'
import { mapActions, mapGetters } from 'vuex'

import StatusChip from '@/components/StatusChip'
import Pagination from '@/components/Pagination'
import ActionMenu from '@/components/ActionMenu'
import ConfirmationModal from '../ConfirmationModal'
import CopyID from '../CopyID'

import { copyToClipboard, showNotification } from '@/utils/generalUtils'
import { GLOBAL_GETTERS, TASK_TYPE } from '@/data/enum'

export default {
  name: 'TaskRecords',
  components: {
    Card,
    Tooltip,
    StatusChip,
    CopyID,
    Button,
    ConfirmationModal,
    ActionMenu,
    Pagination
  },
  props: {
    task: {
      type: Object,
      required: true
    },
    translation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      currPage: 0,
      paginationItems: 30,
      focusedRecordId: null,
      loading: false
    }
  },
  computed: {
    ...mapGetters({ user: GLOBAL_GETTERS.USER }),

    records () {
      return this.task.translation_records
    },
    totalPages () {
      return Math.ceil(this.records.length / this.paginationItems)
    },
    paginatedPages () {
      return this.records.slice(
        this.currPage * this.paginationItems,
        (this.currPage + 1) * this.paginationItems
      )
    },
    isSeniorReview () {
      return this.task.type === TASK_TYPE.REVIEW
    }
  },
  methods: {
    ...mapActions('tasks', ['reAssignTask']),

    handleChangePage (newPage) {
      this.currPage = newPage
    },
    copyUrlToClipboard () {
      const url = `${process.env.VUE_APP_CORE_BASE_URL}/editor/senior_review/${this.task.id}/`
      copyToClipboard(url)
      showNotification(this, 'is-added', 'Successfully copied the editor url')
    },
    clearModal () {
      this.loading = false
      this.focusedRecordId = null
    },
    async reAssign () {
      this.loading = true

      const payload = {
        id: this.focusedRecordId,
        actor: this.user.email
      }

      this.reAssignTask(payload)
        .then(() => showNotification(this, 'is-added', 'Task was reassigned'))
        .catch(() => showNotification(this, 'is-alert', 'Error reassigning task'))
        .finally(() => this.clearModal())
    },
    uadminEditorUrl (editorName) {
      return `${process.env.VUE_APP_CORE_BASE_URL}/uadmin/users/${editorName}`
    },
    getHourlyRate (item) {
      if (!item.hourly_rate) return '---'
      return `$${Math.round(
        (parseFloat(item.hourly_rate / 100) + Number.EPSILON) * 100
      ) / 100}/hour`
    },
    onConfirmAction () {},
    getEditingInfo (item) {
      const out = {
        cost: '---',
        time: '---',
        distance: item.avg_edit_distance ?? '---'
      }

      if (item.editing_time) {
        out.time = `${item.editing_time}s`
      }

      if (item.editing_cost) {
        out.cost = `$${Math.round(
          (parseFloat(item.editing_cost / 100) + Number.EPSILON) * 100
        ) / 100}`
      }

      return out
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .c-Card__body {
  padding: 0 !important;
}

.c-TaskRecords {
  &__table {
    background-color: $un-white;
    width: 100%;

    thead th {
      color: $un-n900;
      font-family: $primary-font;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      text-align: left;
    }

    th,
    td {
      padding: 8px 16px;
      text-align: left;
      vertical-align: middle;
    }

    tr {
      box-shadow: inset 0px -1px 0px 0px $un-n200;
    }

    tbody tr:last-child {
      box-shadow: none;
    }

    td {
      color: $un-n900;
      font-family: $primary-font;
      font-size: 14px;
      line-height: 15px;
      letter-spacing: 0.21px;
      text-align: left;
    }
  }

  &__editor {
    a {
      color: $un-blue;
      font-weight: 600;
    }

    a:hover {
      color: $un-blue;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  &__emptyState {
    display: flex;
    justify-content: center;
    padding: $base-padding;
    width: 100%;

    span {
      font-family: $primary-font;
      font-style: italic;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.25px;
      color: $un-n700;
      margin-right: $base-margin;
    }
  }
}
</style>
