import Vue from 'vue'
import { __handleTasks } from '@/utils/parsingUtils'
import {
  getTasks,
  getTasksBatch,
  getTranslationRecord,
  getTranslationRecords,
  forceDegradation,
  clearSkips,
  assignToEditor,
  cancelTask,
  bulkChangeTasks,
  reassignTask
} from '../services/tarkinService'
import { TARKIN_ACTION } from '../constants/enums'

const getDefaultState = () => ({
  records: {},
  translationTasks: {},
  loading: {}
})

export default {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    resetState (state) {
      state = getDefaultState()
    },
    setTask (state, { id, value }) {
      Vue.set(state.translationTasks, id, value)
    },
    setLoading (state, { id, promise }) {
      Vue.set(state.loading, id, promise)
    }
  },
  actions: {
    async getRecord ({ state }, { recordId, force }) {
      if (!force && state.records[recordId]) {
        return state.records[recordId]
      }

      const promise = getTranslationRecord(recordId)
      const translationRecords = await promise

      return translationRecords?.[0] ?? null
    },
    async getTranslationTasks ({ state, commit }, { taskIds, translationId, force }) {
      if (!force && state.translationTasks[translationId]) {
        return state.translationTasks[translationId]
      }

      let tasksPromise

      if (taskIds?.length) {
        tasksPromise = getTasksBatch(taskIds)
      } else {
        tasksPromise = getTasks(translationId)
      }

      const promise = Promise.all([tasksPromise, getTranslationRecords(translationId)])
      commit('setLoading', { id: translationId, promise })

      const [tasks, translationRecords] = await promise

      translationRecords.forEach(translationRecord => {
        const { task, ...record } = translationRecord
        const taskIndex = tasks.findIndex(({ id }) => id === task.id)

        if (taskIndex >= 0) {
          tasks[taskIndex] = {
            ...tasks[taskIndex],
            ...task,
            translation_records: tasks[taskIndex].translation_records || []
          }
          tasks[taskIndex].translation_records.push(record)
        }
      })

      const parsedTasks = __handleTasks(tasks).parsedTasksArray

      commit('setTask', { id: translationId, value: parsedTasks })
      commit('setLoading', { id: translationId, promise: null })

      return parsedTasks
    },
    async forceDegradation ({ commit }, { taskId, actor }) {
      try {
        const promise = forceDegradation({ task_id: taskId }, actor)
        commit('setLoading', { id: TARKIN_ACTION.FORCE_DEGRADATION, promise })

        const data = await promise
        return data
      } finally {
        commit('setLoading', { id: TARKIN_ACTION.FORCE_DEGRADATION, promise: null })
      }
    },
    async clearSkips ({ commit }, { taskId, actor }) {
      try {
        const promise = clearSkips({ task_id: taskId }, actor)
        commit('setLoading', { id: TARKIN_ACTION.CLEAR_SKIPS, promise })

        const data = await promise
        return data
      } finally {
        commit('setLoading', { id: TARKIN_ACTION.CLEAR_SKIPS, promise: null })
      }
    },
    async assignTask ({ commit }, { taskId, editorName, actor }) {
      try {
        const promise = assignToEditor({ task_id: taskId, editor_name: editorName }, actor)
        commit('setLoading', { id: TARKIN_ACTION.ASSIGN, promise })

        const data = await promise
        return data
      } finally {
        commit('setLoading', { id: TARKIN_ACTION.ASSIGN, promise: null })
      }
    },
    async reAssignTask ({ commit }, { id, actor }) {
      try {
        const promise = reassignTask({ assigned_task_id: id }, actor)
        commit('setLoading', { id: TARKIN_ACTION.REASSIGN_TASK, promise })

        const data = await promise
        return data
      } finally {
        commit('setLoading', { id: TARKIN_ACTION.REASSIGN_TASK, promise: null })
      }
    },
    async cancelReview ({ commit }, { taskId, actor }) {
      try {
        const promise = cancelTask({ task_id: taskId }, actor)
        commit('setLoading', { id: TARKIN_ACTION.CANCEL, promise })

        const data = await promise
        return data
      } finally {
        commit('setLoading', { id: TARKIN_ACTION.CANCEL, promise: null })
      }
    },
    async changePriority ({ commit }, { taskIds, priority }) {
      try {
        const promise = bulkChangeTasks(taskIds, { priority })
        commit('setLoading', { id: TARKIN_ACTION.CANCEL, promise })

        const data = await promise
        return data
      } finally {
        commit('setLoading', { id: TARKIN_ACTION.CANCEL, promise: null })
      }
    }
  }
}
