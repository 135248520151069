/* eslint-disable camelcase */
import { TAG_PURPOSE, BLOCKING_REASON, BLOCKING_BY } from '@/data/enum'
import { clone } from '@/utils/generalUtils'

const ANONYMIZATION_CLASS = 'segment_highlight_anonymization'
const GLOSSARY_CLASS = 'segment_highlight_glossary'
const NO_TRANSLATE_CLASS = 'segment_highlight_no_translate'
const DEFAULT_CLASS = 'segment_default_annotation'

function __patchStringAtTwoIndexes (originalString, start, end, firstPatch, secondPatch) {
  const textInbetween = originalString.substring(start, end)
  const newInbetween = `${firstPatch}${textInbetween}${secondPatch}`
  const firstThird = originalString.substr(0, start)
  const lastThird = originalString.substr(end)

  return `${firstThird}${newInbetween} ${lastThird}`
}

function __generateGlossaryHref (config) {
  const { customerId, glossaryId } = config

  if (!customerId || !glossaryId) return ''
  return `href="https://${customerId}.unbabel.com/linguistic-resources/glossaries/${glossaryId}/terms" rel="noopener noreferrer" target="_blank" title="Glossary"`
}

function __generateMarkupLabel (htmlTag) {
  // Remove any leading/trailing whitespace
  htmlTag = htmlTag.trim()

  // Regular expression to match valid opening, closing, and self-closing tags
  const validTagPattern = /^<\/?([a-zA-Z]+)(?:\s[^>]*)?>$/

  // Extract the tag name
  const match = htmlTag.match(validTagPattern)

  // Add evaluate with is a closing or opening a tag
  if (match) {
    if (htmlTag.startsWith('</')) {
      return { tagType: TAG_PURPOSE.CLOSE, value: match[1] }
    } else {
      return { tagType: TAG_PURPOSE.OPEN, value: match[1] }
    }
  }

  // If no match or invalid tag, return null or an empty string
  return null
}

export function parseTasks (segment, tasks) {
  const segmentTasks = []

  tasks.forEach(task => {
    task.translated_segments.forEach(translatedSegment => {
      if (translatedSegment.segment.id === segment.id) {
        segmentTasks.push({ taskId: task.id, ...translatedSegment })
      }
    })
  })

  return { ...segment, tasks: segmentTasks }
}

export function parseMarkupTags (segment) {
  const { markup_tags } = segment

  if (!markup_tags) return []

  let elIndex

  const blocks = []
  const TAG_TYPE = 'tag'
  const TAG_CLASS = 'markup_tag'

  const addToBlock = (id, tagType, value, start) => {
    const similarBlock = blocks.find(item => item.tagType === tagType)
    let label = ''
    let closingCount = 1

    if (tagType === TAG_PURPOSE.CLOSE) {
      label = '/'
      closingCount = -1
    }

    if (similarBlock) {
      similarBlock.idList.push(id)
      similarBlock.valueList.push(value)
      similarBlock.toBeClosed += closingCount
      similarBlock.label = `${label}${similarBlock.idList[0]}-${id}`
      similarBlock.title = 'Multiple tags'
    } else {
      label = `${label}${id}`
      blocks.push({ type: TAG_TYPE, title: 'Markup tag', class: TAG_CLASS, tagType, valueList: [value], start, end: start, label, idList: [id], toBeClosed: closingCount })
    }
  }

  const sortTags = (a, b) => {
    // Extract tag names by removing angle brackets
    const tagA = a.text.replace(/[<>]/g, '')
    const tagB = b.text.replace(/[<>]/g, '')

    if (tagA < tagB) return 1
    if (tagA > tagB) return -1
    return 0
  }

  markup_tags.sort(sortTags).forEach(tag => {
    const start = +tag.position
    const { value, tagType } = __generateMarkupLabel(tag.text) ?? {}

    if (!value) return

    // start by checking if we are closing a pending tag
    // Example: stack contains `[<a>]` and we are evaluating `</a>`
    elIndex = blocks.findLastIndex(el => el.valueList.includes(value) && el.toBeClosed > 0 && tagType === TAG_PURPOSE.CLOSE)

    // if element was found, then we need to check how is it closing
    // so we can present a unified block instead of two adjacent blocks if needed
    // Example `<a/>` instead of `<a> </a>`
    if (elIndex >= 0) {
      const el = blocks[elIndex]
      el.toBeClosed = el.toBeClosed - 1

      if (start === el.start) {
        el.class = el.class.concat(' self_closing')
        el.tagType = TAG_PURPOSE.SELF_CLOSE
      } else {
        const valueIdx = el.valueList.findLastIndex(val => val === value)
        addToBlock(el.idList[valueIdx], tagType, value, start)
      }
    } else {
      // if we didn't encounter, we need to add it to the list of tags
      // start by finding last id
      const lastId = blocks.reduce((max, _tag) => Math.max(_tag.idList[_tag.idList.length - 1], max), 0)
      const id = lastId + 1

      addToBlock(id, tagType, value, start)
    }
  })

  const anyOutstanding = blocks.some(el => el.toBeClosed > 0)

  if (anyOutstanding) {
    blocks.push({ type: TAG_TYPE, class: `${TAG_CLASS} outstanding`, start: Infinity, end: Infinity, title: 'Outstanding tags' })
  }

  return blocks
}

export function parseAnnotations (segment, config) {
  let { annotations, text } = segment ?? []
  segment.parsedText = text

  if (segment.markup_tags) {
    const markupTags = parseMarkupTags(clone(segment))
    annotations = annotations.concat(...markupTags)
  }

  if (!annotations) return segment

  annotations.forEach((annotation, index) => {
    const { enrichments, category, start, end, type } = annotation ?? {}
    const { notranslate, glossary, is_anonymized } = enrichments ?? {}

    let highlightClass = DEFAULT_CLASS
    let firstTag = `<span title="${segment.category ?? ''}" class="${highlightClass}">`
    let secondTag = '</span>'

    if (type === 'tag') {
      highlightClass = annotation.class
      firstTag = `<span title="${annotation.title}" class="${highlightClass}">`
      secondTag = `${annotation.label ?? ''}</span>`
    } else {
      if (is_anonymized) {
        highlightClass = ANONYMIZATION_CLASS
        firstTag = `<span title="Anonymization" class="${highlightClass}">`
      }

      if (notranslate) {
        highlightClass = NO_TRANSLATE_CLASS
        firstTag = `<span title="No translate" class="${highlightClass}">`
      }

      if (glossary || category === 'glossary') {
        highlightClass = GLOSSARY_CLASS
        firstTag = `<a class="${highlightClass}" ${__generateGlossaryHref(config)}>`
        secondTag = '</a>'
      }
    }

    segment.parsedText = __patchStringAtTwoIndexes(segment.parsedText, start, end, firstTag, secondTag)

    /**
     * By adding an HTML element "<span></span>" to the segment's text we
     * are making all indexes in the highlights outdated. We need to
     * loop through all of them and patch their indexes
     */
    for (let j = index + 1; j < annotations.length; j += 1) {
      const nextHighlightStart = annotations[j].start
      const nextHighlightEnd = annotations[j].end ?? nextHighlightStart

      if (start < nextHighlightStart) annotations[j].start += firstTag.length
      if (end < nextHighlightStart) annotations[j].start += secondTag.length
      if (start < nextHighlightEnd) annotations[j].end += firstTag.length
      if (end < nextHighlightEnd) annotations[j].end += secondTag.length
    }
  })

  return segment
}

export function parseTasksIntoSegments (tasks) {
  const segments = []

  tasks.forEach(task => {
    task.translated_segments.forEach(translatedSegment => {
      segments.push({ ...translatedSegment.segment, tasks: translatedSegment, parsedText: translatedSegment.segment.text })
    })
  })

  return segments
}

export function parseSegments (segments, options) {
  const { tasks } = options ?? {}
  let out

  return segments.map((segment) => {
    out = Object.assign({ }, segment)
    out = parseAnnotations(out, options)
    if (!tasks) return out
    return parseTasks(out, tasks)
  })
}

export function getMatchType (segment) {
  const { tm_match_type, blocking_reason } = segment

  if (blocking_reason) {
    if (blocking_reason === BLOCKING_REASON.ICE || blocking_reason === 'ICE') {
      return BLOCKING_BY.ICE
    } else if (blocking_reason === BLOCKING_REASON.QE || blocking_reason === 'QE') {
      return BLOCKING_BY.QE
    }
    return BLOCKING_BY.TM
  }

  if (tm_match_type) {
    if (tm_match_type === 'ICE') {
      return BLOCKING_BY.ICE
    }

    return BLOCKING_BY.TM
  }
}

export function generateTMInfo (segment) {
  const { is_blocked, blocking_reason, translation_memory_properties } = segment

  const match = getMatchType(segment)

  let title = 'Translation Memory'
  if (is_blocked) title += `\nBlocking reason: ${blocking_reason ?? 'Unknown'}`
  if (match) title += `\nMatch type: ${match}`

  if (translation_memory_properties) {
    Object.entries(translation_memory_properties).forEach(([key, value]) => {
      title += `\r\n${key}=${value}`
    })
  }

  return title
}
