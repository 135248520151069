<template>
  <div
    class="c-ConfirmationModal"
    :class="{ visible: visibility }"
    @click.self.stop="onCancel"
  >
    <div class="c-ConfirmationModal-content">
      <div class="d-flex between-xs">
        <h6>{{ title }}</h6>
        <slot name="title-append" />
      </div>

      <div class="mt-4 mb-6">
        <slot>
          <span class="text--body-2" v-html="body" />
        </slot>
      </div>

      <div class="c-ConfirmationModal-actions">
        <Button
          class="button"
          button-type="is-outlined"
          :label="cancelLabel"
          :disabled="disabled"
          @click="onCancel"
        />

        <Button
          class="button"
          :label="confirmLabel"
          :disabled="disabled"
          @click="onConfirm"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from '@unbabel/ui'

export default {
  name: 'ConfirmationModal',
  components: {
    Button
  },
  props: {
    visibility: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    body: {
      type: String,
      required: false,
      default: ''
    },
    cancelLabel: {
      type: String,
      required: false,
      default: 'Cancel'
    },
    confirmLabel: {
      type: String,
      required: false,
      default: 'Confirm'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    closeModal () {
      this.$emit('toggleModal')
    },
    onCancel () {
      this.$emit('cancel')
    },
    onConfirm () {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-ConfirmationModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all $default-time $default-easing;
  z-index: 1000000;

  &.visible {
    opacity: 1;
    visibility: visible;
  }

  &-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  &-content {
    background-color: $un-white;
    border-radius: $border-radius;
    padding: $base-padding calc(#{$base-padding} * 1.5);
    box-shadow: 0px 10px 10px 0px rgba(174, 185, 203, 0.26),
      0px 14px 28px 0px rgba(174, 185, 203, 0.25);
    min-width: 400px;
    max-width: 600px;
  }

  &-actions {
    display: flex;
    justify-content: flex-end;

    .button:first-child {
      margin-right: calc(#{$base-margin} / 2);
    }
  }
}
</style>
