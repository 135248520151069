import axios from 'axios'

import { SYSTEM_NAME } from '../../data/enum'
import { TARKIN_ACTION } from '../constants/enums'

const V1 = `${process.env.VUE_APP_TARKIN_BASE_URL}/api/v1`
const V2 = `${process.env.VUE_APP_TARKIN_BASE_URL}/v2`

const FILTERS = {
  record: (recordId) => `[{"field":"id","op":"==","value":"${recordId}"}]`,
  records: (jobId) => `[{"field":"group_id","op":"==","value":"${jobId}"}]`,
  tasks: (jobId) => `[{"field":"group_id","op":"==","value":"${jobId}"}]`,
  task: (taskId) => `[{"field":"id","op":"==","value":"${taskId}"}]`,
  taskSearch: (taskIds) => `[{"field":"id","op":"in","value":[${taskIds.map(id => `"${id}"`).join(',')}]}]`
}

function _generateHeader (actor) {
  const headers = {
    'x-system': SYSTEM_NAME
  }
  // Add the x-actor header if actor is not null or empty
  if (actor?.trim()) {
    headers['x-actor'] = actor
  }

  return headers
}

async function _genericPostToTarkin (action, payload, actor) {
  const headers = _generateHeader(actor)
  const url = `${V2}/task_management/translation_tasks:${action}`

  return await axios.post(url, payload, { headers })
}

async function _genericGetFromTarkin (url, filter) {
  try {
    const newUrl = `${url}?page=1&page_size=200&filter=${filter}`
    const { data } = await axios.get(newUrl)
    return data.items
  } catch (e) {
    console.error(e)
  }
}

export function getTranslationRecord (recordId) {
  return _genericGetFromTarkin(`${V1}/translation_records`, FILTERS.record(recordId))
}

export function getTranslationRecords (jobId) {
  return _genericGetFromTarkin(`${V1}/translation_records`, FILTERS.records(jobId))
}

export function getTasks (jobId) {
  return _genericGetFromTarkin(`${V1}/task`, FILTERS.tasks(jobId))
}

export function getTasksBatch (taskIds) {
  return _genericGetFromTarkin(`${V1}/task`, FILTERS.taskSearch(taskIds))
}

export function getTaskDetails (taskId) {
  return _genericGetFromTarkin(`${V1}/task`, FILTERS.task(taskId))
}

export function forceDegradation (payload, actor) {
  return _genericPostToTarkin(TARKIN_ACTION.FORCE_DEGRADATION, payload, actor)
}

export function clearSkips (payload, actor) {
  return _genericPostToTarkin(TARKIN_ACTION.CLEAR_SKIPS, payload, actor)
}

export function assignToEditor (payload, actor) {
  return _genericPostToTarkin(TARKIN_ACTION.ASSIGN, payload, actor)
}

export function reassignTask (payload, actor) {
  return _genericPostToTarkin(TARKIN_ACTION.REASSIGN_TASK, payload, actor)
}

export function cancelTask (payload, actor) {
  return _genericPostToTarkin(TARKIN_ACTION.CANCEL, payload, actor)
}

export function bulkChangeTasks (taskIds, payload) {
  return axios.post(`${V1}/task/update`, { task_ids: taskIds, ...payload })
}
