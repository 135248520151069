<template>
  <div class="c-TranslationFlow" :style="gridStyle">
    <div
      v-for="(step, index) in steps"
      :key="index"
      class="c-TranslationFlowStep"
      :class="generateClass(step)"
      :style="generateStyle(index)"
    >
      <div
        :class="{ clickable: !!outputText(step) }"
        class="c-TranslationFlowStep__container"
        @click="downloadStepOutput(step)"
      >
        <div
          :id="`step-${step.eventId}`"
          class="c-TranslationFlowStep__textBox"
        >
          <p class="text--body-2 text--bold">{{ parseModuleName(step) }}</p>
          <p v-if="parseModuleType(step)" class="text--caption text--light">{{ parseModuleType(step) }}</p>

          <Tooltip
            :message="generateTooltip(step)"
            position="bottom"
            :anchorName="`step-${step.eventId}`"
          />
        </div>
      </div>

      <i class="c-TranslationFlowStep__arrowIcon" />
    </div>
  </div>
</template>

<script>
import { Tooltip } from '@unbabel/ui'
import { format, formatDistanceStrict } from 'date-fns'

import { ModuleState } from '../../constants/enums'
import { downloadFile } from '@/utils/generalUtils'

export default {
  name: 'TranslationFlow',
  components: {
    Tooltip
  },
  props: {
    steps: {
      type: Array,
      required: true
    }
  },
  computed: {
    gridKeys () {
      return this.steps.map((step, index) => `${step.module}-${index}`)
    },
    gridStyle () {
      let out = 'grid-template-areas: "'
      let processed = 0

      this.gridKeys.forEach((key, index) => {
        processed++

        if (index === this.gridKeys.length) out += `${key}" `
        else if (processed !== 0 && processed % 5 === 0) {
          out += `${key}" ". `
          processed++
        } else out += `${key} `
      })

      while (processed % 5 !== 0) {
        out += '. '
        processed++
        if (processed % 5 === 0) out += '"'
      }

      return out
    }
  },
  methods: {
    parseModuleName (step) {
      return step.module.replace(/-(\w+)/g, '').split('_').join(' ')
    },
    parseModuleType (step) {
      const parts = step.module.split('-')
      return parts.length > 1 ? parts[1] : ''
    },
    generateClass (step) {
      return {
        notStarted: step.status === ModuleState.SCHEDULED,
        ongoing: step.status === ModuleState.IN_PROGRESS,
        completed: step.status === ModuleState.COMPLETED,
        failed: step.status === ModuleState.FAILED,
        canceled: step.status === ModuleState.CANCELED,
        skipped: step.status === ModuleState.CANCEL_REQUESTED,
        timedout: step.status === ModuleState.TIMED_OUT,
        resumed: step.module.includes('resume')
      }
    },
    generateStyle (index) {
      return `grid-area: ${this.gridKeys[index]}`
    },
    generateTooltip (step) {
      const addHtml = (title, content) => {
        return `<p class="text--caption">
                    <span class="text--caption--demi">${title}</span>:
                    ${content}
                </p>`
      }

      let msg = `<p style="text-transform: capitalize" class="text-center text--caption--demi mb-2">${this.parseModuleName(step)}</p>`

      if (step.startedAt) {
        msg += addHtml('Started at', format(step.startedAt, 'yyyy-LL-dd HH:mm:ss'))
      }

      if (step.status !== ModuleState.IN_PROGRESS && step.completedAt) {
        msg += addHtml('Completed at', format(step.completedAt, 'yyyy-LL-dd HH:mm:ss'))
        msg += addHtml('Duration', formatDistanceStrict(step.startedAt, step.completedAt))
      }

      if (this.outputText(step)) {
        msg += '<p style="font-style: italic" class="text-center my-2">(click to download step output text)</p>'
      }

      return msg
    },
    outputText (step) {
      return step.results?.find(result => result?.output?.text)?.output?.text
    },
    downloadStepOutput (step) {
      if (this.outputText(step)) {
        downloadFile(`${step.module}.txt`, this.outputText(step))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

.c-TranslationFlow {
  display: grid;
  gap: 1rem;
  width: 100%;

  .c-TranslationFlowStep {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      &:before {
        content: "";
        width: 24px;
        height: 13px;
        display: block;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.756 6.494c0 .35-.15.7-.4.95l-4.8 4.75c-.35.35-.9.35-1.25 0a.875.875 0 010-1.25l4.35-4.35c.05-.05.05-.15 0-.2l-4.35-4.35a.875.875 0 010-1.25c.35-.35.9-.35 1.25 0l4.75 4.75c.3.25.45.6.45.95z' fill='%23C8D0E0'/%3E%3C/svg%3E");
      }

      &.resumed:before {
        width: 24px;
        margin-left: -8px;
        margin-right: 4px;
        background-image: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.4365 5.16496C17.6582 4.94501 18.0175 4.94501 18.2392 5.16496L20.8338 7.73968C20.9402 7.8453 21 7.98856 21 8.13793C21 8.28731 20.9402 8.43056 20.8338 8.53619L18.2392 11.1109C18.0175 11.3308 17.6582 11.3308 17.4365 11.1109C17.2149 10.8909 17.2149 10.5343 17.4365 10.3144L19.0622 8.70115H13.9459C13.6325 8.70115 13.3784 8.44899 13.3784 8.13793C13.3784 7.82687 13.6325 7.57471 13.9459 7.57471H19.0622L17.4365 5.96147C17.2149 5.74152 17.2149 5.38491 17.4365 5.16496ZM3 8.13793C3 7.82687 3.25411 7.57471 3.56757 7.57471H8.10811C8.29788 7.57471 8.47509 7.66883 8.58035 7.82551L13.6011 15.2989H19.0622L17.4365 13.6856C17.2149 13.4657 17.2149 13.1091 17.4365 12.8891C17.6582 12.6692 18.0175 12.6692 18.2392 12.8891L20.8338 15.4638C20.9402 15.5694 21 15.7127 21 15.8621C21 16.0114 20.9402 16.1547 20.8338 16.2603L18.2392 18.835C18.0175 19.055 17.6582 19.055 17.4365 18.835C17.2149 18.6151 17.2149 18.2585 17.4365 18.0385L19.0622 16.4253H13.2973C13.1075 16.4253 12.9303 16.3312 12.8251 16.1745L7.80436 8.70115H3.56757C3.25411 8.70115 3 8.44899 3 8.13793ZM3 15.8621C3 15.551 3.25411 15.2989 3.56757 15.2989H8.10811C8.42157 15.2989 8.67568 15.551 8.67568 15.8621C8.67568 16.1731 8.42157 16.4253 8.10811 16.4253H3.56757C3.25411 16.4253 3 16.1731 3 15.8621Z" fill="%23C8D0E0"/></svg>');
      }
    }

    &.completed {
      .c-TranslationFlowStep__textBox {
        background-color: $un-green-lightest;
        border-color: $un-green-dark;

        p {
          color: $un-green-dark;
        }
      }

      .c-TranslationFlowStep__container::after {
        border-color: transparent transparent transparent $un-green-lightest;
      }
    }

    &.ongoing {
      .c-TranslationFlowStep__textBox {
        background-color: $un-blue;
        border-color: $un-blue-dark;
        p {
          color: $un-white;
        }
      }

      .c-TranslationFlowStep__container::after {
        border-color: transparent transparent transparent $un-blue;
      }
    }

    &.notStarted {
      .c-TranslationFlowStep__textBox {
        background-color: $un-white;
        border: 1px solid $un-gray-light;
        p {
          color: $un-n700;
        }
      }

      .c-TranslationFlowStep__container::after {
        border-color: transparent transparent transparent $un-white;
      }
    }

    &.skipped {
      .c-TranslationFlowStep__textBox {
        background-color: $un-n50;
        border-color: $un-n700;
        p {
          color: $un-n700;
        }
      }

      .c-TranslationFlowStep__container::after {
        border-color: transparent transparent transparent $un-n50;
      }
    }

    &.timedout {
      .c-TranslationFlowStep__textBox {
        background-color: $un-n50;
        border-color: $un-n700;
        p {
          color: $un-n700;
        }
      }

      .c-TranslationFlowStep__container::after {
        border-color: transparent transparent transparent $un-n50;
      }
    }

    &.failed,
    &.canceled {
      .c-TranslationFlowStep__textBox {
        background-color: $un-red;
        border-color: $un-red-darker;
        p {
          color: $un-white;
        }
      }

      .c-TranslationFlowStep__container::after {
        border-color: transparent transparent transparent $un-red;
      }
    }

    &__container {
      padding: 0 4px;
      height: 67px;
      width: 100%;

      &.clickable {
        &:hover > .c-TranslationFlowStep__textBox {
          cursor: pointer;
          border-width: 2px;
          border-style: solid;
        }
      }
    }

    &__textBox {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: $border-radius;

      > p {
        padding: 4px;
        line-height: 15px;
        text-transform: capitalize;
        text-align: center;
      }
    }
  }
}
</style>
