<template>
  <div>
    <Card :title="`Human translation tasks (${tasks.length})`">
      <div slot="body">
        <div
          v-if="loadingTasks"
          class="d-flex center-xs text--body-2 text--light py-4"
        >
          Loading tasks
        </div>

        <div
          v-else-if="tasks.length === 0"
          class="d-flex center-xs text--body-2 text--light py-4"
        >
          No tasks have been created yet.
        </div>

        <table v-else>
          <thead>
            <tr>
              <th @click.stop>
                <Checkbox
                  label=""
                  iconStyle="minus"
                  :data="allRowsSelected"
                  @change="onRowSelected()"
                />
              </th>
              <th />
              <th>Information</th>
              <th>Status</th>
              <th>Editors</th>
              <th>Devices</th>
              <th>Deadline</th>
              <th />
              <th>
                <ActionMenu
                  :disabled="!selectedRows.length"
                  :menu-items="getTaskActionMenuItems(selectedRows)"
                  position="top"
                  @menuClick="onActionClick($event, selectedRows)"
                />
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="item in tasks"
              :key="item.id"
              @click="onRowClicked(item)"
            >
              <td @click.stop>
                <Checkbox
                  label=""
                  iconStyle="minus"
                  :data="item.selected"
                  @change="onRowSelected(item)"
                />
              </td>
              <td>
                <p class="text--bold">{{ item.parsed_type || item.type }}</p>
                <p class="text--caption text--light">
                  Priority {{ item.priority }}
                </p>
                <CopyID
                  :extendable="false"
                  :itemId="item.id"
                  class="mt-1"
                  entity="task"
                  small
                />
              </td>

              <td>
                <div class="text--caption--demi my-1">
                  <span>Skips</span>
                  <span class="text--light ml-1">
                    {{ item.task_skipped_count }}
                  </span>
                </div>

                <div class="text--caption--demi mb-1">
                  <span>Words</span>
                  <span class="text--light ml-1">
                    {{ item.num_words || 0 }}
                  </span>
                </div>

                <div class="text--caption--demi">
                  <span> Edit distance </span>
                  <span class="text--light ml-1">
                    {{ item.task_edit_distance }}
                  </span>
                </div>
              </td>

              <td>
                <StatusChip
                  :status-config="statusOptions[item.task_status]"
                  :status="item.task_status"
                />
              </td>

              <td>{{ getEditorCount(item) }}</td>
              <td>{{ item.parsed_devices || "---" }}</td>
              <td v-html="getDeadline(item)" />

              <td>
                <p class="text--caption">Creation Date</p>
                <p class="text--caption--demi">
                  {{ item.parsed_created_at || "---" }}
                </p>

                <div v-if="item.row_parsed_completed_at" class="mt-2">
                  <p class="text--caption">Completed at</p>
                  <p class="text--caption--demi">
                    {{ item.row_parsed_completed_at || "---" }}
                  </p>
                </div>
              </td>

              <td @click.stop="">
                <ActionMenu
                  :menu-items="getTaskActionMenuItems([item])"
                  position="top"
                  @menuClick="onActionClick($event, [item])"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>

    <ConfirmationModal
      :visibility="modal.active !== null"
      :title="modal.title"
      :body="modal.body"
      :disabled="modal.loading"
      @cancel="clearModal"
      @confirm="onConfirmAction"
    >
      <template #title-append>
        <div v-if="selectedRows.length > 1" class="text--caption">
          Changing <span class="text--caption--demi"> {{ selectedRows.length }} tasks </span>
        </div>
      </template>

      <InputText
        v-if="modal.active === ACTION.ASSIGN_TASK"
        :disabled="modal.loading"
        :label="modal.body"
        :data="modal.value"
        @input="onInput"
      />

      <NumberPicker
        v-else-if="modal.active === ACTION.CHANGE_PRIORITY"
        :label="modal.body"
        :data="modal.value"
        @change="onInput"
      />
    </ConfirmationModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Card, InputText, Checkbox, NumberPicker } from '@unbabel/ui'

import ActionMenu from '@/components/ActionMenu'
import StatusChip from '@/components/StatusChip'
import ConfirmationModal from '../ConfirmationModal'
import CopyID from '../CopyID'

import { showNotification } from '@/utils/generalUtils'
import { TranslationState } from '../../constants/enums'

import { statusOptions } from '@/data/status'
import { GLOBAL_GETTERS, TASK_TYPE, HT_TASK_STATUS } from '@/data/enum'

const ACTION = {
  CLEAR_SKIPS: 'clear_skips',
  FORCE_DEGRADATION: 'force_degradation',
  ASSIGN_TASK: 'task_assign',
  CANCEL_REVIEW: 'cancel',
  CHANGE_PRIORITY: 'priority'
}

const ACTION_LABEL = {
  [ACTION.CLEAR_SKIPS]: 'Clear skips',
  [ACTION.FORCE_DEGRADATION]: 'Force degradation',
  [ACTION.ASSIGN_TASK]: 'Assign to ...',
  [ACTION.CANCEL_REVIEW]: 'Cancel',
  [ACTION.CHANGE_PRIORITY]: 'Change priority'
}

export default {
  name: 'TranslationTasksPreview',
  components: {
    Card,
    StatusChip,
    Checkbox,
    CopyID,
    ActionMenu,
    InputText,
    ConfirmationModal,
    NumberPicker
  },
  props: {
    translation: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      ACTION,
      statusOptions,

      tasks: [],
      modal: { active: null },
      taskActionMenuItems: Object.values(ACTION).map(value => ({ value, label: ACTION_LABEL[value] }))
    }
  },
  computed: {
    ...mapGetters({ user: GLOBAL_GETTERS.USER, loading: 'tasks/loading' }),
    ...mapState('tasks', ['loading', 'translationTasks']),
    currentTasks () {
      return this.translationTasks[this.translation.request.uid] ?? []
    },
    loadingTasks () {
      return !!this.loading[this.translation.request.uid]
    },
    selectedRows () {
      return this.tasks.filter(task => task.selected)
    },
    allRowsSelected () {
      return this.tasks.every(task => task.selected)
    }
  },
  methods: {
    ...mapActions('tasks', [
      'clearSkips',
      'forceDegradation',
      'assignTask',
      'cancelTask',
      'changePriority'
    ]),
    getDeadline (item) {
      const timeElapsed = item.time_elapsed ?? item.time_taken

      let deadlineClass = 'text--bold'
      if (item.exceeded_sla) {
        deadlineClass += ' text--error'
      }

      return `<span class="${deadlineClass}">${timeElapsed}</span> / ${item.parsed_timeout_seconds}`
    },
    getEditorCount (item) {
      return item.translation_records?.length || 0
    },
    onRowClicked (item) {
      this.$router.push({
        name: 'Flexible Task',
        params: { id: this.$route.params.id, taskId: item.id },
        query: this.$route.query
      })
    },
    onRowSelected (item) {
      if (!item) {
        this.tasks.forEach(task => {
          task.selected = !this.selectedRows.length
        })
      } else {
        item.selected = !item.selected
      }
    },
    getTaskActionMenuItems (items) {
      let out = this.taskActionMenuItems

      const allEnqueued = items.every(item => item.task_status === HT_TASK_STATUS.ENQUEUED)
      const allClientReview = items.every(item => item.type === TASK_TYPE.CLIENT_REVIEW)
      const isRunning = this.translation.status === TranslationState.IN_PROGRESS

      // Only allow to cancel client review tasks
      if (!allClientReview) out = out.filter(({ value }) => value !== ACTION.CANCEL_REVIEW)

      // If tasks not queued or translation not running, do not allow to cancel or change priority
      if (!allEnqueued || !isRunning) {
        out = out.filter(({ value }) => ![ACTION.CANCEL_REVIEW, ACTION.CHANGE_PRIORITY].includes(value))
      }

      return out
    },
    onInput (value) {
      this.modal.value = value
    },
    clearModal () {
      this.modal.active = null
      this.modal.value = null
      this.modal.items = []
      this.modal.loading = false
    },
    onActionClick (option, items) {
      let id
      const ref = process.env.VUE_APP_TARKIN_BASE_URL

      if (items.length === 1) id = items[0].category_id ?? ''

      this.modal.active = option.value
      this.modal.title = ACTION_LABEL[option.value]
      this.modal.items = items

      switch (option.value) {
        case ACTION.CLEAR_SKIPS:
          this.modal.body = 'Are you sure you want to proceed?'
          break
        case ACTION.FORCE_DEGRADATION:
          this.modal.body =
            'By forcing task degradation you will push the task in the general pool of translators.'
          if (id) {
            this.modal.body += `<div> <a href="${ref}/category/${id}" target="_blank"> See task details on Tarkin </a> </div>`
          }
          break
        case ACTION.ASSIGN_TASK:
          this.modal.body = 'Please add the username'
          break
        case ACTION.CANCEL_REVIEW:
          this.modal.body =
            'The task will be delivered right after the last community human translation step, without the client review.'
          break
        case ACTION.CHANGE_PRIORITY:
          this.modal.body = 'Please add a new priority'
          this.modal.value = items[0].priority
          break
        default:
          this.clearModal()
      }
    },
    async handlePromises (promises, { success, reject }) {
      const res = await Promise.allSettled(promises)

      const failed = res.filter(({ status }) => status === 'rejected')

      if (failed.length) {
        const errorMessage = failed[0]?.reason?.response?.data?.detail?.message ?? 'Unknown error'
        showNotification(this, 'is-alert', `It wasn't possible to perform action on ${failed.length} tasks :: ${errorMessage}`)
      } else {
        showNotification(this, 'is-added', success)
        this.$emit('refresh')
      }

      this.clearModal()
    },
    saveChanges (item) {
      const taskId = item.id
      const actor = this.user.email
      const payload = { taskId, actor }

      switch (this.modal.active) {
        case ACTION.CLEAR_SKIPS:
          return this.clearSkips(payload)
        case ACTION.FORCE_DEGRADATION:
          return this.forceDegradation(payload)
        case ACTION.ASSIGN_TASK:
          return this.assignTask({ ...payload, editorName: this.modal.value })
        default:
          return new Promise(resolve => resolve())
      }
    },
    onConfirmAction () {
      this.modal.loading = true

      const messages = {
        [ACTION.CLEAR_SKIPS]: {
          success: 'Skips were successfully cleared',
          reject: 'Failed to clear task skips'
        },
        [ACTION.FORCE_DEGRADATION]: {
          success: 'Tasks were force degraded',
          reject: 'Failed to force degradate'
        },
        [ACTION.ASSIGN_TASK]: {
          success: `Tasks assigned to ${this.modal.value}`,
          reject: `Failed to assign tasks to ${this.modal.value}`
        },
        [ACTION.CANCEL_REVIEW]: {
          success: 'Tasks successfully cancelled',
          reject: 'Failed to cancel tasks'
        },
        [ACTION.CHANGE_PRIORITY]: {
          success: 'Tasks priority changed to ' + this.modal.value,
          reject: 'Failed to change priorities'
        }
      }

      let promises = []

      if (this.modal.active === ACTION.CHANGE_PRIORITY) {
        const taskIds = this.modal.items.map(item => item.id)
        promises.push(this.changePriority({ taskIds, priority: this.modal.value }))
      } else {
        promises = this.modal.items.map(this.saveChanges)
      }

      this.handlePromises(promises, messages[this.modal.active])
    }
  },
  watch: {
    currentTasks: {
      handler () {
        this.tasks = this.currentTasks.map(task => ({
          ...task,
          selected: false
        }))
      },
      immediate: true,
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/scss/variables.scss";

::v-deep .c-Card__body {
  padding: 0 !important;
}

table {
  width: 100%;
  border-spacing: 0;

  th,
  td {
    padding: 0 0 0 calc(#{$base-padding} * 1.5);
    text-align: left;
    vertical-align: middle;
    padding: $base-padding;
  }

  tr {
    box-shadow: inset 0px -1px 0px 0px $un-n200;
  }

  tbody tr:hover {
    background-color: $un-n50;
    cursor: pointer;
  }

  tbody tr:last-child {
    box-shadow: none;
  }

  th:last-child,
  td:last-child {
    padding-right: calc(#{$base-padding} * 1.5) !important;
  }

  thead th {
    color: $un-n900;
    font-family: $primary-font;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
  }

  td {
    cursor: pointer;
    color: $un-n900;
    font-family: $primary-font;
    font-size: 14px;
    line-height: 15px;
    letter-spacing: 0.21px;
    text-align: left;
  }

  td:first-child {
    font-weight: 600;
  }

  .c-Tooltip__messageContainer {
    margin-left: 40px !important;
  }
}
</style>
