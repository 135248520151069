<template>
  <div
    ref="actionMenu"
    class="c-ActionMenu">
    <button
      type="button"
      :class="{ isActive: visibility, disabled }"
      :disabled="disabled"
      @click="toggleMenu"
      @mouseenter="handleMouseOver(true)"
      @mouseleave="handleMouseOver(false)">
      <Icon
        class="icon"
        icon="more-vertical"
        :icon-style="iconStyle" />
    </button>
    <Menu
      class="c-ActionMenu__menu"
      :class="{ [position]: true }"
      :items="menuItems"
      :active="visibility"
      @menuClick="handleMenuClick" />
  </div>
</template>

<script>
import {
  Menu,
  Icon
} from '@unbabel/ui'
import { MIXPANEL_EVENTS } from '@/data/enum'
import { trackEvent } from '@/utils/generalUtils'

export default {
  name: 'ActionMenu',
  components: {
    Menu,
    Icon
  },
  props: {
    menuItems: {
      type: Array,
      required: true,
      validator: (data) => {
        const conditions = []
        data.forEach(item => {
          conditions.push(Object.keys(item).indexOf('label'))
          conditions.push(Object.keys(item).indexOf('value'))
        })

        return Object.keys(data).length === 0 || conditions.every(cond => cond > -1)
      }
    },
    position: {
      type: String,
      required: false,
      default: 'bottom',
      validator: (data) => {
        return ['top', 'bottom'].includes(data)
      }
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      visibility: false,
      isHovered: false
    }
  },
  computed: {
    iconStyle () {
      if (this.disabled) return 'is-disabled-gray'

      return (this.visibility || this.isHovered) ? 'is-primary' : 'is-dark-gray'
    }
  },
  methods: {
    toggleMenu () {
      this.visibility = !this.visibility

      if (this.visibility) {
        trackEvent(MIXPANEL_EVENTS.ACTION_MENU_OPEN, {
          area: this.$route.name,
          type: this.$refs.actionMenu.getAttribute('data-test-id') // used also for the e2e tests
        })
      }
    },
    onClickOutside (event) {
      const { actionMenu } = this.$refs
      if (!actionMenu || actionMenu.contains(event.target)) {
        return
      }
      this.visibility = false
    },
    handleMenuClick (item, index) {
      this.$emit('menuClick', item, index)
      this.visibility = false
    },
    handleMouseOver (isHover) {
      this.isHovered = isHover
    }
  },
  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.onClickOutside)
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss';

.c-ActionMenu {
  position: relative;
  z-index: 1001;
  margin-top: -5px;

  button {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: transparent;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(.disabled) {
      cursor: pointer;
      &:hover, &.isActive {
        background-color: $un-blue-light;
      }
    }
  }

  &__menu {
    position: absolute;
    right: 0;

    &.top{
      bottom: 50px;
    }

    &.bottom {
      top: 50px;
    }
  }
}
</style>
